<template>
    <v-container>
        <v-card v-if="item" class="grow">
            <v-card-title class="">
                <span>{{ item.subsidiary_name }}</span>
            </v-card-title>
            <v-card-subtitle class="">
                {{ $t('views.customers.Register.subsidiary_document', {
                    document: item.formated_subsidiary_document
                }) }}
            </v-card-subtitle>
            <v-card-text class="">
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <div class="overline">{{ $t('globals.date') }}</div>
                        <div class="font-weight-bold headline">{{ item.created_at | date }}</div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <div class="overline">{{ $t('globals.hour') }}</div>
                        <div class="font-weight-bold headline">{{ item.created_at | timeS }}</div>
                    </v-col>
                    <v-col v-if="hasSpentValue" cols="12" sm="6" md="4">
                        <div class="overline">{{ $t('globals.value') }}</div>
                        <div class="font-weight-bold headline">{{ item.spent_value | currency }}</div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <div class="overline">{{ pointsDataText }}</div>
                        <div class="font-weight-bold headline">{{ item.points_amount | dynamicPoint }} | {{ item.points_value | currency }}</div>
                    </v-col>
                    <v-col v-if="item.expires_at" cols="12" sm="6" md="4">
                        <div class="overline">{{ $t('globals.expires') }}</div>
                        <div class="font-weight-bold headline">{{ item.expires_at | fromNow }}</div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div v-else class="text-center mt-5">
            {{ $t('globals.loading') }}
        </div>

        <v-btn class="mt-3" @click="goBack"
            color="secondary" text
        >{{ $t('actions.back') }}</v-btn>
    </v-container>
</template>

<script>
import { mapFilters } from '@/utils/mapFilters'
import Consumption from '@/models/Consumption'
import Generator from '@/models/Generator'

export default {
    props: {
        item: Object
    },
    created() {
        if (!this.item)
            this.goBack()

        // Adiciona direcionamento correto para o breadcrumbs
        this.$route.meta.breadcrumbs[2].to = { name: 'history', params: { id: this.item.customer_id } }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        }
    },
    computed: {
        hasSpentValue() {
            return this.item instanceof Generator
        },
        pointsDataText() {
            return this.item instanceof Generator   ? this.$t('globals.received') :
                   this.item instanceof Consumption ? this.$t('globals.expended') : this.$t('globals.reversed')
        },
    },
}
</script>