var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.item
        ? _c(
            "v-card",
            { staticClass: "grow" },
            [
              _c("v-card-title", {}, [
                _c("span", [_vm._v(_vm._s(_vm.item.subsidiary_name))])
              ]),
              _c("v-card-subtitle", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("views.customers.Register.subsidiary_document", {
                        document: _vm.item.formated_subsidiary_document
                      })
                    ) +
                    " "
                )
              ]),
              _c(
                "v-card-text",
                {},
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
                        _c("div", { staticClass: "overline" }, [
                          _vm._v(_vm._s(_vm.$t("globals.date")))
                        ]),
                        _c(
                          "div",
                          { staticClass: "font-weight-bold headline" },
                          [_vm._v(_vm._s(_vm._f("date")(_vm.item.created_at)))]
                        )
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
                        _c("div", { staticClass: "overline" }, [
                          _vm._v(_vm._s(_vm.$t("globals.hour")))
                        ]),
                        _c(
                          "div",
                          { staticClass: "font-weight-bold headline" },
                          [_vm._v(_vm._s(_vm._f("timeS")(_vm.item.created_at)))]
                        )
                      ]),
                      _vm.hasSpentValue
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6", md: "4" } },
                            [
                              _c("div", { staticClass: "overline" }, [
                                _vm._v(_vm._s(_vm.$t("globals.value")))
                              ]),
                              _c(
                                "div",
                                { staticClass: "font-weight-bold headline" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(_vm.item.spent_value)
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("v-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
                        _c("div", { staticClass: "overline" }, [
                          _vm._v(_vm._s(_vm.pointsDataText))
                        ]),
                        _c(
                          "div",
                          { staticClass: "font-weight-bold headline" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("dynamicPoint")(_vm.item.points_amount)
                              ) +
                                " | " +
                                _vm._s(
                                  _vm._f("currency")(_vm.item.points_value)
                                )
                            )
                          ]
                        )
                      ]),
                      _vm.item.expires_at
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6", md: "4" } },
                            [
                              _c("div", { staticClass: "overline" }, [
                                _vm._v(_vm._s(_vm.$t("globals.expires")))
                              ]),
                              _c(
                                "div",
                                { staticClass: "font-weight-bold headline" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fromNow")(_vm.item.expires_at)
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", { staticClass: "text-center mt-5" }, [
            _vm._v(" " + _vm._s(_vm.$t("globals.loading")) + " ")
          ]),
      _c(
        "v-btn",
        {
          staticClass: "mt-3",
          attrs: { color: "secondary", text: "" },
          on: { click: _vm.goBack }
        },
        [_vm._v(_vm._s(_vm.$t("actions.back")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }